import * as CONSTANT from "./Constants.js";
import UNIVERSAL from "../../Config/Config.js";
import { setLoader, unsetLoader }
    from "../../Common/loader/action.js"
import { set_snack_bar } from "../../Common/snackbar/action.js";
import { onLogout } from "../../Auth/Actions.js";

export function viewModel(token,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "model/view_model", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({

            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(
                        { type: CONSTANT.MODEL, payload: { result: responseJson.data.result } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(
                        { type: CONSTANT.MODEL, payload: { result: [] } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function addModel(token, model, type) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "model/add_model", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                model, type
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(viewModel(token,))
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateModel(token, user_id, model, type,) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "model/update_model", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                id: user_id, model, type
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(viewModel(token,))
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteModel(token, user_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "model/delete_model", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                id: user_id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(viewModel(token,))
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function setExecutiveId(payload) {
    return {
        type: CONSTANT.EXECUTIVE_ID,
        payload: payload
    };
}



