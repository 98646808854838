import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Containers/Controller_Cont.js";
import Drawer from "../Containers/DrawerCont.js";

import Login from "../../Auth/Containers/Login_Cont.js";
// import Error from "../../404";

import Executive from "../../Pages/Executive/Containers/ExecutiveCont.js";
import ExecutiveClients from "../../Pages/Executive/Containers/ExecutivClientsCont.js";
import Client from "../../Pages/Client/Containers/ClientCont.js";
import ClientDetails from "../../Pages/Client/Containers/ClientDetailsCont.js";
import Feedbackform from "../../Pages/Client/Components/Feedbackform.js";
import Model from "../../Pages/Model/Container/ModelCont.js";
import Profile from "../../Pages/Profile/Containers/ProfileCont.js";

class Routers extends Component {
    render() {
        const { login } = this.props;
        let body;
        
        
        if (login.isHome) {
            body = (
                <div>
                    <main style={{ flexGrow: 1 }}>
                        <Controller />
                        <Routes>
                            <Route exact path="/" element={<Login />} />
                            <Route exact path="/Feedbackform/:id" element={<Feedbackform />} />

                        </Routes>
                    </main>
                </div>
            );
        } else {
            if (login.type === "SA") {
                body = (
                    <div>
                        <Drawer type={login.type} />
                        <main style={{ flexGrow: 1, paddingTop: 70 }}>
                            <Controller />
                            <Routes>
                                <Route exact path="/" element={<Executive />} />
                                <Route exact path="/Client" element={<Client />} />
                                <Route exact path="/ExecutiveClients" element={<ExecutiveClients />} />
                                <Route exact path="/ClientDetails/:id" element={<ClientDetails />} />
                                <Route exact path="/Feedbackform/:id" element={<Feedbackform />} />
                                <Route exact path="/model" element={<Model />} />
                                <Route exact path="/Profile" element={<Profile />} />
                            </Routes>
                        </main>
                    </div>
                );
            }
            else if (login.type === 'E') {
                body = (
                    <div>
                        <Drawer type={login.type} />
                        <main style={{ flexGrow: 1, paddingTop: 70 }}>
                            <Controller />
                            <Routes>
                                <Route exact path="/" element={<Client />} />
                                <Route exact path="/Client" element={<Client />} />
                                <Route exact path="/ClientDetails/:id" element={<ClientDetails />} />
                                <Route exact path="/Feedbackform/:id" element={<Feedbackform />} />
                                <Route exact path="/Profile" element={<Profile />} />
                            </Routes>
                        </main>
                    </div>
                );
            }

        }

        return <Router>{body}</Router>;
    }
}

export default Routers;
