import React, { Component } from 'react';
import CommonTable from "../../../Common/Table/Table";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import Loader from '../../../Common/loader/containers/loader_cont';
import Snackbar from "../../../Common/snackbar/components/snackbar";

function withNavigation(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    return <Component {...props} navigate={navigate} location={location} params={params} />;
  };
}

const data = [
  { id: 1, name: "John Doe", age: 28, email: "john.doe@example.com", emp_id: "EMP001", status: "Active" },
  { id: 2, name: "Jane Smith", age: 34, email: "jane.smith@example.com", emp_id: "EMP002", status: "Active" },
  { id: 3, name: "Sam Green", age: 23, email: "sam.green@example.com", emp_id: "EMP003", status: "Active" }
];

const TableWithNavigation = ({ data, columns, props, unique }) => {
  const navigate = useNavigate();

  const handleRowClick = (unique) => {
    props.setExecutiveId(unique)
    navigate(`/ExecutiveClients`);
  };

  return <CommonTable data={data} columns={columns} onRowClick={handleRowClick} unique={unique} />;
};

class Executive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      columns: [
        { header: "Name", key: "name" },
        { header: "Email", key: "email" },
        { header: "Employee_Id", key: "emp_id" },
        { header: "Action", key: "action" },
      ],
      mobcolumns: [
        { header: "Executive", key: "name" },
        { header: "Action", key: "action" },
      ],
      emp_id: '',
      mobile: '',
      email: '',
      name: '',
      add: false,
      edit: false,
      deleteM: false,
      page: 1,
      pageSize: 10,
      user_id: 0,
      isMobileView: window.innerWidth <= 768 // Initial check for mobile view
    };
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    this.props.viewExecutive(this.props.login.token, this.state.page, this.state.pageSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobileView: window.innerWidth <= 768 }); // Adjust breakpoint as needed
  };

  renderName = (addedUser) => {
    const { isMobileView } = this.state;

    if (!isMobileView) {
      return <span>{addedUser.name}</span>;
    } else {
      return (
        <>
          <span>{addedUser.name}</span><br />
          <span>{addedUser.email}</span><br />
          <span>{addedUser.emp_id}</span><br />
        </>
      );
    }
  };

  processData = (data) => {
    return Array.isArray(data) && data.map((e) => ({
      ...e,
      name: this.renderName(e),
      status: (
        <span>{'Active'}</span>
      ),
      action: (
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid item>
            <Button className='table-text' style={{ color: "rgb(20, 28, 76)", backgroundColor: "rgba(77, 85, 124, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(20, 28, 76)', padding: 0 }}
              onClick={(event) => {
                event.stopPropagation();
                this.setState({
                  edit: true,
                  user_id: e._id,
                  name: e.name,
                  emp_id: e.emp_id,
                  email: e.email,
                  mobile: e.mobile,

                });
              }}>
              Edit
            </Button>
          </Grid>
          <Grid item>
            <Button className='table-text' style={{ color: "rgb(212, 44, 44)", backgroundColor: "rgba(220, 85, 85, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(212, 44, 44)', padding: 0 }}
              onClick={(event) => {
                event.stopPropagation();
                this.setState({ deleteM: true, user_id: e._id, name: e.name, });
              }}>
              Delete
            </Button>
          </Grid>
        </Grid>
      ),
    }))
  }

  processColumn = (columns) => {
    const { isMobileView } = this.state;
    if (isMobileView) {
      // Return mobile columns
      return this.state.mobcolumns.map((e) => ({
        ...e,
        action: 'action'
      }));
    } else {
      // Return desktop columns
      return columns.map((e) => ({
        ...e,
        action: 'action'
      }));
    }
  }

  render() {
    const handleChangePage = (event, newPage) => {
      this.props.viewExecutive(this.props.login.token, newPage, this.state.pageSize)
      this.setState({ page: newPage });
    };

    const { emp_id, mobile, email, name, add, edit, deleteM, user_id } = this.state;
    const newdata = this.processData(this.props.exec.all_exe);
    const newcolumns = this.processColumn(this.state.columns);

    return (
      <Grid padding={1} >
        <Grid display={'flex'} justifyContent={'flex-end'}>
          <Grid pb={1}>
            <Button className='table-text' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.setState({
                  add: true, name: "",
                  emp_id: "",
                  email: "",
                  mobile: "",
                });
              }}>
              Add Executive
            </Button>
          </Grid>
        </Grid>

        <Card>
          <TableWithNavigation data={newdata} columns={newcolumns} props={this.props} unique={'_id'} />
        </Card>
        <Pagination
          style={{ display: "flex", justifyContent: 'center', padding: '10px' }}
          count={this.props.exec.exe_length}
          size="small"
          shape='rounded'
          onChange={handleChangePage}
        />

        {/* /-------------------------------- ADD ------------------------------------/ */}
        <Dialog
          maxWidth="md"
          fullWidth
          open={add}
          onClose={() => {
            this.setState({
              add: false
            })
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
            <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Add Employee</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Name:</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="text"
                      fullWidth
                      size='small'
                      placeholder='Executive Name'
                      onChange={(event) => {
                        this.setState({ name: event.target.value });
                      }}
                      value={name}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Employee Id :</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="text"
                      fullWidth
                      size='small'
                      placeholder='Employee Id'
                      onChange={(event) => {
                        this.setState({ emp_id: event.target.value });
                      }}
                      value={emp_id}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={0} spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Phone No.:</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="number"
                      fullWidth
                      size='small'
                      placeholder='Executive Phone No.'
                      onChange={(event) => {
                        this.setState({ mobile: event.target.value });
                      }}
                      value={mobile}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Email Id :</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="email"
                      fullWidth
                      size='small'
                      placeholder='Executive Email Id'
                      onChange={(event) => {
                        this.setState({ email: event.target.value });
                      }}
                      value={email}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions style={{ padding: "15px" }}>
            <Button style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.props.addExecutive(this.props.login.token, name, emp_id, email, mobile, 'E')
                this.setState({
                  add: false,
                  name: "",
                  emp_id: "",
                  email: "",
                  mobile: "",
                })
              }}>
              Submit
            </Button>
            <Button style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.setState({
                  add: false,
                  name: "",
                  emp_id: "",
                  email: "",
                  mobile: "",
                })
              }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* /-------------------------------- EDIT ------------------------------------/ */}
        <Dialog
          maxWidth="md"
          fullWidth
          open={edit}
          onClose={() => {
            this.setState({
              edit: false
            })
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
            <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Edit Employee</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Name:</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="text"
                      fullWidth
                      size='small'
                      placeholder='Executive Name'
                      onChange={(event) => {
                        this.setState({ name: event.target.value });
                      }}
                      value={name}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Employee Id :</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="text"
                      fullWidth
                      size='small'
                      placeholder='Employee Id'
                      onChange={(event) => {
                        this.setState({ emp_id: event.target.value });
                      }}
                      value={emp_id}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={0} spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Phone No.:</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="number"
                      fullWidth
                      size='small'
                      placeholder='Executive Phone No.'
                      onChange={(event) => {
                        this.setState({ mobile: event.target.value });
                      }}
                      value={mobile}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Email Id :</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="email"
                      fullWidth
                      size='small'
                      placeholder='Executive Email Id'
                      onChange={(event) => {
                        this.setState({ email: event.target.value });
                      }}
                      value={email}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions style={{ padding: "15px" }}>
            <Button style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.props.updateExecutive(this.props.login.token, user_id, name, emp_id, email, mobile, 'E')
                this.setState({
                  edit: false
                })
              }}>
              Submit
            </Button>
            <Button style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.setState({
                  edit: false
                })
              }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        {/* /-------------------------------- DELETE ------------------------------------/ */}
        <Dialog
          maxWidth="xs"
          fullWidth
          open={deleteM}
          onClose={() => {
            this.setState({
              eddeleteMit: false
            })
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
            <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Delete Employee</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              Are you sure you want to delete this employee  <span style={{ fontWeight: 600 }}>{name}</span> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              this.props.deleteExecutive(this.props.login.token, user_id);
              this.setState({ deleteM: false });
            }} style={{ backgroundColor: "rgb(212, 44, 44)", color: "white", textTransform: 'capitalize' }}>
              Delete
            </Button>
            <Button style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.setState({
                  deleteM: false
                })
              }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Loader />
        {/* <Snackbar
          open={this.props.snackbar.response_received}
          close_snack_bar={this.props.close_snack_bar}
          message={this.props.snackbar.message}
        /> */}

      </Grid>
    );
  }
}

export default withNavigation(Executive);
