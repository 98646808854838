import * as CONSTANT from "../Executive/Constants.js";
import UNIVERSAL from "../../Config/Config.js";
import { setLoader, unsetLoader }
    from "../../Common/loader/action.js"
import { set_snack_bar } from "../../Common/snackbar/action.js";
import { onLogout } from "../../Auth/Actions.js";

export function viewExecutive(token, page, pageSize) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "user/view", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                page, pageSize
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(
                        { type: CONSTANT.EXECUTIVE, payload: { result: responseJson.data.data, count: responseJson.totalPages } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(
                        { type: CONSTANT.EXECUTIVE, payload: { result: [], count: 0 } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function addExecutive(token, name, emp_id, email, mobile, access) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "user/register", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                name, emp_id, email, mobile, access
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(viewExecutive(token, 1, 10))
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateExecutive(token, user_id, name, emp_id, email, mobile, access) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "user/update", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                user_id, name, emp_id, email, mobile, access
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(viewExecutive(token, 1, 10))
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
export function deleteExecutive(token, user_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "user/delete", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                user_id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(viewExecutive(token, 1, 10))
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function setExecutiveId(payload) {
    return {
        type: CONSTANT.EXECUTIVE_ID,
        payload: payload
    };
}



