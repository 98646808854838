import React, { Component } from "react";
import PropTypes from 'prop-types';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Typography,
  TableBody,
  Paper,
  TableContainer,
} from "@mui/material";
import "../Style/common.css";

class CommonTable extends Component {
  static propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
      header: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRowClick: PropTypes.func,
    unique: PropTypes.string.isRequired
  };

  static defaultProps = {
    columns: [],
    data: [],
    onRowClick: () => { },
    unique: 'id'
  };

  render() {
    
    const { columns, data, onRowClick, unique } = this.props;
    return (
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: 'rgba(77, 85, 124, 0.3)' }}>
              <TableCell style={{ width: '100px' }} >
                <Typography className='table-text' textAlign={'center'} fontWeight={600}>Sl No</Typography>
              </TableCell>
              {columns.map((column, index) => (
              
                <TableCell key={index} >
                  <Typography className="table-text" textAlign={'center'} fontWeight={600}>{column.header}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) && data.map((item, rowIndex) => (
              <TableRow
                hover
                key={item[unique]}
                style={{ cursor: item.status === "Disabled" ? "default" : "pointer" }}
                onClick={() => item.status !== "Disabled" && onRowClick(item[unique])}
              >
                <TableCell >
                  <Typography className='table-text2' textAlign={'center'}>{rowIndex + 1}</Typography>
                </TableCell>
                {columns.map((column, colIndex) => {
                  const value = column.key.includes('.')
                    ? column.key.split('.').reduce((obj, key) => obj?.[key], item)
                    : item[column.key];
                  return (
                    <TableCell key={`${rowIndex}-${colIndex}`} >
                      {column.key === "action" ? (
                        <Typography className="table-text2" textAlign={'center'}>
                          {value}
                        </Typography>
                      ) : (
                        <Typography
                          className="table-text2"
                          textAlign={'center'}
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering row click
                            onRowClick(item[unique]);
                          }}
                        >
                          {value}
                        </Typography>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default CommonTable;
