import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../Components/ExecutiveClients";
import { close_snack_bar } from "../../../Common/snackbar/action";
import { viewExecutiveClient, setClientId } from "../../Client/Actions";

export class Controller extends Component {
    render() {
        return (
            <Login {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        exec: store.exec,
        client: store.client,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        viewExecutiveClient: (token, executive_id, page, pageSize) => {
            dispatch(viewExecutiveClient(token, executive_id, page, pageSize));
        },
        setClientId: (id) => {
            dispatch(setClientId(id));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);