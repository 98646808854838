import React, { Component } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/MenuRounded';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Drive from '../../Common/Image/central group logo.png'
import { Link } from 'react-router-dom';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { FontDownload } from '@mui/icons-material';
import { Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';

const drawerWidth = 240;

class DrawerAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      add: false,
      startDate: '',
      endDate: '',
    };
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  render() {
    const { window } = this.props;
    const { mobileOpen, endDate, startDate, add } = this.state;

    const drawer = (
      <Box onClick={this.handleDrawerToggle} sx={{ textAlign: 'center' }}>
        <Box sx={{ flexGrow: 1, display: { xs: 'block', sm: 'none' }, padding: 2 }}>
          <img src={Drive} alt='TestDrive' height={100} />
        </Box>
        <Divider />
        <List>
          <Link to='/'>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={'Executive'} style={{ color: '#FFFFFF' }} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/Client'>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={'Client'} style={{ color: '#FFFFFF' }} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link to='/model'>
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={'Model'} style={{ color: '#FFFFFF' }} />
              </ListItemButton>
            </ListItem>
          </Link>
          {this.props.type === "SA" &&
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={'Export Report'} style={{ color: '#FFFFFF' }} onClick={() => {
                  // this.props.getTestDriveReport(this.props.login.token)
                  this.setState({
                    add: true, startDate: "",
                    endDate: "",
                  })
                }} />
              </ListItemButton>
            </ListItem>
          }
        </List>
      </Box>

    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar component="nav" sx={{ backgroundColor: 'rgb(20, 28, 76)' }}>
          <Toolbar>
            {this.props.type === "SA" &&
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={this.handleDrawerToggle}
                sx={{ display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            }
            <Link to='/Profile'>
              <PersonPinIcon style={{ color: 'white', height: 35, width: 'auto', }} />
            </Link>
            <Link to='/' style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
              <Box sx={{ height: 64, display: 'flex', alignItems: 'center' }}>
                <img src={Drive} alt='TestDrive' style={{ height: 150, width: 'auto', }} />
              </Box>
            </Link>
            <Box sx={{ display: { xs: 'none', sm: 'block' }, marginLeft: 'auto' }}>
              {this.props.type === "SA" &&
                <>
                  <Link to='/'>
                    <Button sx={{ color: '#fff' }}>
                      {'Executive'}
                    </Button>
                  </Link>
                  <Link to='/Client'>
                    <Button sx={{ color: '#fff' }}>
                      {'Client'}
                    </Button>
                  </Link>
                  <Link to='/model'>
                    <Button sx={{ color: '#fff' }}>
                      {'Model'}
                    </Button>
                  </Link>
                  <Button sx={{ color: '#fff' }}
                    onClick={() => {
                      this.setState({
                        add: true, startDate: "",
                        endDate: "",
                      })
                    }}
                  >
                    {'Export Report'}
                  </Button>
                </>
              }
            </Box>
          </Toolbar>
        </AppBar>

        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={this.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'rgb(20, 28, 76)' },
            }}
          >
            {drawer}
          </Drawer>
        </nav>


        <Dialog
          // maxWidth="md"
          fullWidth
          open={add}
          onClose={() => {
            this.setState({
              add: false,
              startDate: "",
              endDate: "",
            })
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
            <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Export Data</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={5}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Start Date:</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="date"
                      fullWidth
                      size='small'
                      placeholder='DD-MM-YYYY'
                      onChange={(event) => {
                        this.setState({ startDate: event.target.value });
                      }}
                      value={startDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} sm={12} md={5}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>End Date :</DialogContentText>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8}>
                    <TextField
                      type="date"
                      fullWidth
                      size='small'
                      placeholder='DD-MM-YYYY'
                      onChange={(event) => {
                        this.setState({ endDate: event.target.value });
                      }}
                      value={endDate}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ padding: "15px" }}>
            <Button style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {

                this.props.getTestDriveReport(this.props.login.token, startDate, endDate)
                this.setState({
                  add: false,
                  startDate: "",
                  endDate: "",
                })
              }}>
              Export Data
            </Button>
            <Button style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.setState({
                  add: false
                })
              }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box >

    );
  }
}

export default DrawerAppBar;
