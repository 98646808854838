import React, { Component } from 'react';
import axios from 'axios';
import { GoogleMap, LoadScript, Polyline, Marker } from '@react-google-maps/api';

class TravelTracker extends Component {
  state = {
    path: [],
    currentLocation: null,
  };

  componentDidMount() {
    const { path } = this.props;
    this.setState({ path });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.setState({
            currentLocation: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            }
          });
        },
        (error) => {
          console.error('Error accessing location:', error);
        }
      );
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevProps.path.length !== this.props.path.length) {
  //     this.setState({ path: this.props.path });
  //     this.snapToRoads();
  //   }
  // }

  snapToRoads = () => {
    const { path } = this.state;

    axios.get('https://roads.googleapis.com/v1/snapToRoads', {
      params: {
        interpolate: true,
        key: 'AIzaSyA1NWtHAoW-by59g6tticj5Xf6Y3i9btv0',
        path: path.map(point => `${point.lat},${point.lng}`).join('|'),
      },
    })
      .then(response => {
        const snappedPath = response.data.snappedPoints.map(point => ({
          lat: point.location.latitude,
          lng: point.location.longitude,
        }));
        this.setState({ path: snappedPath });
      })
      .catch(error => {
        console.error('Error snapping to roads', error);
      });
  };

  render() {
    const { path, currentLocation } = this.state;

    const mapContainerStyle = {
      width: '100%',
      height: '600px',
    };

    const center = path.length > 0 ? path[Math.floor(path.length / 2)] : { lat: 0, lng: 0 };

    const startPoint = path.length > 0 ? path[0] : null;
    const endPoint = path.length > 1 ? path[path.length - 1] : null;

    return (
      <div style={{ marginBottom: '20px', fontFamily: 'Arial, sans-serif' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Travel Path</h2>
        <LoadScript googleMapsApiKey="AIzaSyA1NWtHAoW-by59g6tticj5Xf6Y3i9btv0">
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={12}
          >
            {startPoint && (
              <Marker
                position={startPoint}
                icon="https://maps.google.com/mapfiles/kml/paddle/go.png" // Change this to your start icon URL
              />
            )}
            {endPoint && (
              <Marker
                position={endPoint}
                icon="https://maps.google.com/mapfiles/kml/paddle/red-circle.png" // Change this to your end icon URL
              />
            )}
            {currentLocation && (
              <Marker
                position={currentLocation}
                icon="https://maps.google.com/mapfiles/kml/paddle/blu-circle.png" // Change this to your current location icon URL
              />
            )}
            <Polyline path={path} options={{ strokeColor: '#007BFF', strokeOpacity: 0.75 }} />
          </GoogleMap>
        </LoadScript>
      </div>
    );
  }
}

export default TravelTracker;
