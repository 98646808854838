import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../Components/Executive";
import { close_snack_bar } from "../../../Common/snackbar/action";
import { addExecutive, deleteExecutive, setExecutiveId, updateExecutive, viewExecutive } from "../Actions";
export class Controller extends Component {
    render() {
        return (
            <Login {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        exec: store.exec,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        viewExecutive: (token, page, pageSize) => {
            dispatch(viewExecutive(token, page, pageSize));
        },
        addExecutive: (token, name, emp_id, email, mobile, access) => {
            dispatch(addExecutive(token, name, emp_id, email, mobile, access));
        },
        updateExecutive: (token, user_id, name, emp_id, email, mobile, access) => {
            dispatch(updateExecutive(token, user_id, name, emp_id, email, mobile, access));
        },
        deleteExecutive: (token, user_id) => {
            dispatch(deleteExecutive(token, user_id));
        },
        setExecutiveId: (id) => {
            dispatch(setExecutiveId(id));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);