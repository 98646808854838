import React, { Component } from "react";
import { connect } from "react-redux";
import Profile from "../Components/Profile";
import { close_snack_bar } from "../../../Common/snackbar/action";
import { onLogout, passwordChange } from "../../../Auth/Actions";

export class Controller extends Component {
    render() {
        return (
            <Profile {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        passwordChange: (token, user_id, oldpassword, newpassword) => {
            dispatch(passwordChange(token, user_id, oldpassword, newpassword));
          },
        onLogout: () => {
            dispatch(onLogout());
          },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);