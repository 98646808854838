import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../Components/Model";
import { close_snack_bar } from "../../../Common/snackbar/action";
import { addModel, deleteModel, setExecutiveId, updateModel, viewModel } from "../Actions";
export class Controller extends Component {
    render() {
        return (
            <Login {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        exec: store.exec,
        model: store.model
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        viewModel: (token) => {
            dispatch(viewModel(token,));
        },
        addModel: (token, model, type, ) => {
            dispatch(addModel(token, model, type));
        },
        updateModel: (token, user_id, model, type,) => {
            dispatch(updateModel(token, user_id, model, type, ));
        },
        deleteModel: (token, user_id) => {
            dispatch(deleteModel(token, user_id));
        },
        setExecutiveId: (id) => {
            dispatch(setExecutiveId(id));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);