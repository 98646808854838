import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../Components/ClientDetails";
import { close_snack_bar } from "../../../Common/snackbar/action";
import { addTestDrive, endTestDrive, sendOtp, setClientId, updateTestDrive, verifyOtp, viewClientDetails, viewClientTestDrive, viewTravelRecord, exportExcel } from "../Actions";
import { viewModel } from "../../Model/Actions";
import { updateClient } from "../Actions";
export class Controller extends Component {
    render() {
        return (
            <Login {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        exec: store.exec,
        client: store.client,
        model: store.model
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        setClientId: (id) => {
            dispatch(setClientId(id));
        },
        viewClientDetails: (token, client_id) => {
            dispatch(viewClientDetails(token, client_id));
        },
        addTestDrive: (token, taken_by, model, taken_user) => {
            dispatch(addTestDrive(token, taken_by, model, taken_user));
        },
        updateTestDrive: (token, client_id, test_id, longitude, latitude) => {
            dispatch(updateTestDrive(token, client_id, test_id, longitude, latitude));
        },
        viewTravelRecord: (token, test_drive) => {
            dispatch(viewTravelRecord(token, test_drive));
        },
        viewClientTestDrive: (token, client_id) => {
            dispatch(viewClientTestDrive(token, client_id));
        },
        sendOtp: (token, client_id, test_id) => {
            dispatch(sendOtp(token, client_id, test_id));
        },
        verifyOtp: (token, client_id, test_id, otp, longituted, latituted) => {
            dispatch(verifyOtp(token, client_id, test_id, otp, longituted, latituted));
        },
        endTestDrive: (token, client_id, test_id, longitude, latitude) => {
            dispatch(endTestDrive(token, client_id, test_id, longitude, latitude));
        },
        viewModel: (token) => {
            dispatch(viewModel(token));
        },
        updateClient: (token, user_id, name, email, mobile, type) => {
            dispatch(updateClient(token, user_id, name, email, mobile, type));
        },
        exportExcel: (taken_user) => {
            dispatch(exportExcel(taken_user));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);