import * as CONSTANT from "../Client/Constants.js";
import UNIVERSAL from "../../Config/Config.js";
import { setLoader, unsetLoader }
    from "../../Common/loader/action.js"
import { set_snack_bar } from "../../Common/snackbar/action.js";
import { onLogout } from "../../Auth/Actions.js";
import * as XLSX from 'xlsx';
export function viewClient(token, page, pageSize) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "client/view", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                page, pageSize
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(
                        { type: CONSTANT.CLIENT, payload: { result: responseJson.data.data, count: responseJson.data.totalPages } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(
                        { type: CONSTANT.CLIENT, payload: { result: [], count: 0 } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewExecutiveClient(token, executive_id, page, pageSize) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "client/viewExecutive", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                executive_id, page, pageSize
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(
                        { type: CONSTANT.EXECUTIVE_CLIENT, payload: { result: responseJson.data.data, count: responseJson.data.totalPages } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(
                        { type: CONSTANT.EXECUTIVE_CLIENT, payload: { result: [], count: 0 } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function addClient(token, user_id, name, email, mobile, access, type) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "client/register", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                user_id, name, email, mobile, access
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    if (type === 'SA') {
                        dispatch(viewClient(token, 1, 10))
                    }
                    else {
                        dispatch(viewExecutiveClient(token, user_id, 1, 10))
                    }
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateClient(token, user_id, name, email, mobile, type) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "client/update", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                user_id, name, email, mobile
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    if (type === 'SA') {
                        dispatch(viewClient(token, 1, 10))
                        dispatch(viewClientDetails(token, user_id))
                    }
                    else {
                        dispatch(viewExecutiveClient(token, user_id, 1, 10))
                        dispatch(viewClientDetails(token, user_id))
                    }
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function deleteClient(token, user_id, type) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "client/delete", {
            method: "DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                user_id,
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    if (type === 'SA') {
                        dispatch(viewClient(token, 1, 10))
                    }
                    else {
                        dispatch(viewExecutiveClient(token, user_id, 1, 10))
                    }
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function setClientId(payload) {
    return {
        type: CONSTANT.CLIENT_ID,
        payload: payload
    };
}

export function viewClientDetails(token, client_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "client/view_by_id", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                client_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(
                        { type: CONSTANT.CLIENT_DETAILS, payload: { result: responseJson.data.result } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(
                        { type: CONSTANT.EXECUTIVE_CLIENT, payload: { result: [], count: 0 } }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function addTestDrive(token, taken_by, model, taken_user) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "tdrive/register", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                taken_user, taken_by, model
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {

                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(viewClientTestDrive(token, taken_user));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewClientTestDrive(token, client_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "tdrive/viewClientTestDrive", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                client_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(
                        { type: CONSTANT.TEST_DRIVE, payload: responseJson.data }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(
                        { type: CONSTANT.TEST_DRIVE, payload: [] }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function viewTravelRecord(token, test_drive) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "tdrive/viewTravelRecord", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                test_drive
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(
                        { type: CONSTANT.DRIVE_RECORD, payload: responseJson.data.mappedDrives }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(
                        { type: CONSTANT.DRIVE_RECORD, payload: [] }
                    )
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function sendOtp(token, client_id, test_id) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "tdrive/sendOtp", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                test_id
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch(viewClientTestDrive(token, client_id));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {

                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function verifyOtp(token, client_id, test_id, otp, longitude, latitude) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "tdrive/verifyOtp", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                test_id, otp, longitude, latitude
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch({ type: CONSTANT.CHECK_START, payload: responseJson.data })
                    dispatch(viewClientTestDrive(token, client_id));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch({ type: CONSTANT.CHECK_START, payload: {} })
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function updateTestDrive(token, client_id, test_id, longitude, latitude) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "tdrive/update", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                test_id, longitude, latitude
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch({ type: CONSTANT.CHECK_START, payload: responseJson.data })
                    dispatch(viewClientTestDrive(token, client_id));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function endTestDrive(token, client_id, test_id, longitude, latitude) {
    return (dispatch) => {

        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "tdrive/end", {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                token
            },
            body: JSON.stringify({
                test_id, longitude, latitude
            }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch({ type: CONSTANT.REMOVE_START, payload: null })
                    dispatch(viewClientTestDrive(token, client_id));
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                }
                else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}

export function exportExcel(taken_user) {
    return (dispatch) => {
        dispatch(setLoader());
        return fetch(UNIVERSAL.BASEURL + "tdrive/exportExcel", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ taken_user }),
        })
            .then((response) => response.json())
            .then((responseJson) => {
                console.log("hello", responseJson.data);
                if (responseJson.statusCode === 201 || responseJson.statusCode === 200) {
                    dispatch({ type: CONSTANT.EXCEL, payload: responseJson.data });
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));

                    // Call the exportToExcel function after data is fetched successfully
                    exportToExcel(responseJson.data, 'TestDriveData');
                } else {
                    dispatch(unsetLoader());
                    dispatch(set_snack_bar(true, responseJson.message));
                }
            })
            .catch((error) => {
                dispatch(unsetLoader());
                dispatch(set_snack_bar(true, "Check Your Internet Connection"));
                console.error(error);
            });
    };
}
const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
};

// Helper function to convert seconds to hh:mm:ss format
const formatDuration = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);
    return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
};


const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
};

const exportToExcel = (data, fileName) => {
    const transformedData = data.map(item => {
        const {
            startPlaceName = "Unknown location",
            endPlaceName = "Unknown location",
            testDrive,
            feedback = []
        } = item;

        const {
            model: { model = "", type = "" } = {},
            taken_by: { name: takenByName = "", email: takenByEmail = "", emp_id: takenById = "", mobile: takenByMobile = "" } = {},
            taken_user: { name: takenUserName = "", email: takenUserEmail = "", mobile: takenUserMobile = "" } = {},
            distance = "",
            travelTime = "",
            start_time = "",
            end_time = ""
        } = testDrive || {};

        return {
            takenUserName,
            takenUserEmail,
            takenUserMobile,
            takenByName,
            model,
            date: formatDate(start_time),
            startPlaceName,
            startTime: formatTime(start_time),
            endPlaceName,
            endTime: formatTime(end_time),
            distance,
            travelTime: formatDuration(travelTime),
            feedback
        };
    });

    const headers = [
        { v: 'User Name', s: { font: { bold: true } } },
        { v: 'User Email', s: { font: { bold: true } } },
        { v: 'User Mobile', s: { font: { bold: true } } },
        { v: 'Taken By (Executive) Name', s: { font: { bold: true } } },
        { v: 'Model', s: { font: { bold: true } } },
        { v: 'Date', s: { font: { bold: true } } },
        { v: 'Start Place', s: { font: { bold: true } } },
        { v: 'Start Time', s: { font: { bold: true } } },
        { v: 'End Place', s: { font: { bold: true } } },
        { v: 'End Time', s: { font: { bold: true } } },
        { v: 'Travel Distance', s: { font: { bold: true } } },
        { v: 'Travel Time', s: { font: { bold: true } } }
    ];

    const worksheetData = [headers.map(header => header.v), ...transformedData.map(item => [
        item.takenUserName,
        item.takenUserEmail,
        item.takenUserMobile,
        item.takenByName,
        item.model,
        item.date,
        item.startPlaceName,
        item.startTime,
        item.endPlaceName,
        item.endTime,
        item.distance,
        item.travelTime
    ])];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Apply cell styling
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let row = range.s.r; row <= range.e.r; row++) {
        for (let col = range.s.c; col <= range.e.c; col++) {
            const cell = worksheet[XLSX.utils.encode_cell({ r: row, c: col })];
            if (cell) {
                cell.s = {
                    border: {
                        top: { style: 'thin', color: { rgb: '000000' } },
                        bottom: { style: 'thin', color: { rgb: '000000' } },
                        left: { style: 'thin', color: { rgb: '000000' } },
                        right: { style: 'thin', color: { rgb: '000000' } }
                    },
                    alignment: {
                        vertical: 'center',
                        horizontal: 'center',
                        wrapText: false
                    },
                    font: row === 0 ? { bold: true } : {}
                };
            }
        }
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'AllData');

    // Create model-specific sheets with feedback
    transformedData.forEach(item => {
        const { model, feedback } = item;

        if (feedback && feedback.length > 0) {
            const feedbackData = feedback.map(fb => [fb.question, fb.response, fb.rating]);

            const feedbackSheetData = [
                [{ v: 'Question', s: { font: { bold: true } } }, { v: 'Response', s: { font: { bold: true } } }, { v: 'Rating', s: { font: { bold: true } } }],
                ...feedbackData.map(fb => fb.map(field => ({ v: field, s: { alignment: { wrapText: true } } })))
            ];

            const feedbackSheet = XLSX.utils.aoa_to_sheet(feedbackSheetData);

            // Adjust row height for feedback
            const feedbackRange = XLSX.utils.decode_range(feedbackSheet['!ref']);
            for (let row = feedbackRange.s.r + 1; row <= feedbackRange.e.r; row++) {
                feedbackSheet['!rows'] = feedbackSheet['!rows'] || [];
                feedbackSheet['!rows'][row] = { hpx: 100 }; // Adjust height as needed
            }

            XLSX.utils.book_append_sheet(workbook, feedbackSheet, model);
        }
    });

    XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

