import { initializeApp } from "firebase/app";
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CAPTCHA = "CAPTCHA";
export const PASSWORD_GENERATE = "PASSWORD_GENERATE";

export const PROFILEDEATAILS = "PROFILEDEATAILS";
export const EDITPASSWORD = "EDITPASSWORD";

// const firebaseConfig = {
//     apiKey: "AIzaSyA1NWtHAoW-by59g6tticj5Xf6Y3i9btv0",
//     authDomain: "testdrive-428606.firebaseapp.com",
//     projectId: "testdrive-428606",
//     storageBucket: "testdrive-428606.appspot.com",
//     messagingSenderId: "845152425749",
//     appId: "1:845152425749:web:f56b3d837f73fd4a03fb1c"
//   };

const firebaseConfig = {
  apiKey: "AIzaSyDSPDCwt2bd4_Lk9HC_67my6ntHk1Acgnk",
  authDomain: "testdrive-624d3.firebaseapp.com",
  projectId: "testdrive-624d3",
  storageBucket: "testdrive-624d3.appspot.com",
  messagingSenderId: "784679152306",
  appId: "1:784679152306:web:c2d16e535c023fa78e77ab",
  measurementId: "G-11XHT7899F"
};

export const app = initializeApp(firebaseConfig);













export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const HANDLEDRAWER = "HANDLEDRAWER";
export const SETMOBILE = "SETMOBILE";
export const SET_ALL_ORG = "SET_ALL_ORG";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_ORG_DETAILS = "SET_ORG_DETAILS";
export const SET_ORG_USER_DEATILS = "SET_ORG_USER_DEATILS";
export const SET_ORG_ID = "SET_ORG_ID";
export const SET_ALL_DATA = "SET_ALL_DATA";
export const SET_USER = "SET_USER";
export const SET_ORG = "SET_ORG";
export const SETLOGINORG = "SETLOGINORG";
export const SET_ORG_USER_DETAILS = "SET_ORG_USER_DETAILS";
export const SET_ORG_DETAILS2 = "SET_ORG_DETAILS2";
export const VIEW_ALL_DEPARTMENT = "VIEW_ALL_DEPARTMENT";
export const VIEW_ALL_PRIORITY = "VIEW_ALL_PRIORITY";
export const VIEW_ALL_STATUS = "VIEW_ALL_STATUS";
export const SET_DOC = "SET_DOC";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_EMAIL = "SET_EMAIL";
export const SET_HOME = "SET_HOME";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_WALET_BY_ID = "SET_WALET_BY_ID";