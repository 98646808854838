import React, { Component } from 'react';
import { TextField, Box, Typography, RadioGroup, FormControlLabel, Radio, Button, Card, CardContent, Grid } from '@mui/material';
import '../../../Common/Style/feedback.css';
import { useLocation, useParams } from 'react-router-dom';
import UNIVERSAL from "../../../Config/Config";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} location={useLocation()} />;
}

class FeedbackForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responses: Array(11).fill({ rating: '', response: '' }),
      check: false,
      test_drive: {},
      feedback1: '',
      feedback2: '',
      feedback3: '',
      feedback4: '',
      feedback5: '',
      feedback6: '',
      feedback7: '',
      feedback8: '',
      feedback9: '',
      feedback10: '',
      feedback11: ''
    };
  }

  handleRatingChange = (questionIndex, rating) => {
    this.setState({ [`feedback${questionIndex + 1}`]: rating });
  };

  handleTextChange = (questionIndex, event) => {
    this.setState({ [`feedback${questionIndex + 1}`]: event.target.value });
  };

  handleSubmit = async () => {
    const payload = {
      test_id: this.props.params.id,
      feedbackData: {
        feedback1: this.state.feedback1,
        feedback2: this.state.feedback2,
        feedback3: this.state.feedback3,
        feedback4: this.state.feedback4,
        feedback5: this.state.feedback5,
        feedback6: this.state.feedback6,
        feedback7: this.state.feedback7,
        feedback8: this.state.feedback8,
        feedback9: this.state.feedback9,
        feedback10: this.state.feedback10,
        feedback11: this.state.feedback11,
      }
    }
    try {
      const response = await fetch(UNIVERSAL.BASEURL + "tdrive/feedback", {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (result.statusCode === 201 || result.statusCode === 200) {
        this.checkTestDrive(this.props.params.id)
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  checkTestDrive = async () => {
    const payload = {
      test_id: this.props.params.id
    }
    try {
      const response = await fetch(UNIVERSAL.BASEURL + "tdrive/view_by_id", {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (result.statusCode === 201 || result.statusCode === 200) {
        this.setState({
          test_drive: result.data,
          feedback1: result.data.feedback1,
          feedback2: result.data.feedback2,
          feedback3: result.data.feedback3,
          feedback4: result.data.feedback4,
          feedback5: result.data.feedback5,
          feedback6: result.data.feedback6,
          feedback7: result.data.feedback7,
          feedback8: result.data.feedback8,
          feedback9: result.data.feedback9,
          feedback10: result.data.feedback10,
          feedback11: result.data.feedback11
        });
        if (result.data.status === "feedback") {
          this.setState({ check: true });
        } else {
          this.setState({ check: false });
        }
      } else {
        this.setState({ check: false });
      }
    } catch (error) {
      console.error('Error checking test drive:', error);
    }
  };

  componentDidMount() {
    this.checkTestDrive(this.props.params.id)
  }

  render() {
    const questions = [
      'Test Drive Route and duration?',
      'Sales Consultants ability to answer your queries?',
      'Outside visibility from inside of the car?',
      'Maneuverability & ease of handling of car?',
      'Ambience of the car cabin during test drive (noise, vibration & harshness level)?',
      'Performance of air conditioner?',
      'How do you rate the braking performance of the car?',
      'Overall ride quality & driving comfort?',
      'How do you rate "Fun to Drive" element in the car?',
      'Are you considering to buy our car?',
      'When will you finalize the purchase?'
    ];
    const ratings = ['Excellent', 'Good', 'Average', 'Poor'];

    return (
      <Box className="feedback-form">
        {!this.state.check ? (
          <Card>
            <CardContent>
              <Typography variant="h4" component="h2" gutterBottom>
                Feedback Form
              </Typography>
              <Grid container spacing={3}>
                {questions.map((question, index) => (
                  <Grid item xs={12} key={index}>
                    <Box className="question-container">
                      <Typography variant="h6" component="h4">
                        {question}
                      </Typography>
                      {index < 9 ? (
                        <RadioGroup
                          value={this.state[`feedback${index + 1}`]}
                          onChange={(e) => this.handleRatingChange(index, e.target.value)}
                        >
                          {ratings.map((rating, i) => (
                            <FormControlLabel
                              key={i}
                              value={rating}
                              control={<Radio />}
                              label={rating}
                            />
                          ))}
                        </RadioGroup>
                      ) : (
                        <TextField
                          className="text-response"
                          label="Your response"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={2}
                          value={this.state[`feedback${index + 1}`]}
                          onChange={(e) => this.handleTextChange(index, e)}
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
              </Grid>
              <Box className="submit-button">
                <Button variant="contained" color="primary"
                  onClick={() => {
                    this.handleSubmit();
                  }}>
                  Submit
                </Button>
              </Box>
            </CardContent>
          </Card>
        ) : (
          <Card>
            <CardContent>
              <Typography variant="h5" component="h3">
               Thank you for Feedback 
              </Typography>
            </CardContent>
          </Card>
        )}
      </Box>
    );
  }
}

export default withParams(FeedbackForm);
