// import { initializeApp } from "firebase/app";
export const CLIENT = "CLIENT";
export const EXECUTIVE_CLIENT = "EXECUTIVE_CLIENT";
export const CLIENT_ID = "CLIENT_ID";
export const CLIENT_DETAILS = "CLIENT_DETAILS";
export const TEST_DRIVE = "TEST_DRIVE";
export const CHECK_START = "CHECK_START";
export const DRIVE_RECORD = "DRIVE_RECORD";
export const REMOVE_START = "REMOVE_START";
export const EXCEL = "EXCEL";
