import React, { Component } from 'react'
import { Button, Card, CardActions, CardContent, DialogActions, Grid, TextField, Typography } from '@mui/material'
import { Link, Redirect } from "react-router-dom";

export default class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            oldpassword: '',
            newpassword: '',
        }
    }
    componentDidMount() {

    }
    render() {
        const { edit, oldpassword, newpassword } = this.state;
        const { login, passwordChange } = this.props;
        return (
            <Grid>
                <Grid container>
                    <Grid item xs={0.5} sm={1.5} md={2.5} lg={3.5} />
                    <Grid item xs={11} sm={10} md={7} lg={5} padding={2}>
                        <Card style={{ backgroundColor: 'rgba(77, 85, 124, 0.3)' }}>
                            <CardContent>
                                <Grid container >
                                    <Grid item xs={9.5} sm={10}>
                                        <center>
                                            <Typography style={{ fontSize: 25, fontFamily: 'Hubballi', letterSpacing: 1 }}><b>Profile</b></Typography>
                                        </center>
                                    </Grid>
                                    <Grid item xs={2.5} sm={2}>
                                        <Button className='table-text' style={{ color: "rgb(20, 28, 76)", backgroundColor: "rgba(77, 85, 124, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(20, 28, 76)', padding: 0 }}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                this.setState({
                                                    edit: true,
                                                });
                                            }}>
                                            Edit
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={4}>
                                        <Typography><b>Name</b></Typography>
                                        <Typography><b>Password</b></Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography>: {this.props.login.name}</Typography>
                                        <Typography>: ******</Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={0.5} sm={1.5} md={2.5} lg={3.5} />
                </Grid>
                {/*-------------------------------------Edit-----------------------------------*/}
                {edit ?
                    <Grid container>
                        <Grid item xs={0.5} sm={1.5} md={2.5} lg={3.5} />
                        <Grid item xs={11} sm={10} md={7} lg={5} padding={2}>
                            <Card style={{ backgroundColor: 'rgba(77, 85, 124, 0.3)' }}>
                                <CardContent>
                                    <Grid container >
                                        <Grid item xs={9.5} sm={10}>
                                            <center>
                                                <Typography style={{ fontSize: 25, fontFamily: 'Hubballi', letterSpacing: 1 }}><b>Edit Profile</b></Typography>
                                            </center>
                                        </Grid>
                                        <Grid item xs={2.5} sm={2}>
                                            <Button className='table-text' style={{ color: "rgb(20, 28, 76)", backgroundColor: "rgba(77, 85, 124, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(20, 28, 76)', padding: 0 }}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    this.setState({
                                                        edit: false,
                                                    });
                                                }}>
                                                Close
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Typography><b>Old Password</b></Typography>
                                            <TextField size='small' fullWidth value={oldpassword}
                                                onChange={(event) => {
                                                    this.setState({ oldpassword: event.target.value });
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} mt={2}>
                                            <Typography><b>New Password</b></Typography>
                                            <TextField size='small' fullWidth mt={1} value={newpassword}
                                                onChange={(event) => {
                                                    this.setState({ newpassword: event.target.value });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions style={{ justifyContent: 'center' }}>
                                    <Link to='/'>
                                        <Button className='table-text2' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", }}
                                            onClick={() => {
                                                localStorage.removeItem("token");
                                                passwordChange(login.token, login.user_id, oldpassword, newpassword)
                                            }}
                                        >
                                            Save Changes
                                        </Button>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={0.5} sm={1.5} md={2.5} lg={3.5} />
                    </Grid>
                    : ''
                }
                <DialogActions style={{ justifyContent: 'center' }}>
                    <Link to='/'>
                        <Button className='table-text2' style={{ backgroundColor: "rgb(212, 44, 44)", color: "white" }}
                            onClick={() => {
                                localStorage.removeItem("token");
                                this.setState({ redirect: true });
                                this.props.onLogout();
                            }}
                        >
                            Log out
                        </Button>
                    </Link>
                </DialogActions>
            </Grid>
        )
    }
}
