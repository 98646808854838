import React, { Component } from 'react'
import CommonTable from "../../../Common/Table/Table";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Pagination from '@mui/material/Pagination';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../Common/loader/containers/loader_cont';
import Snackbar from "../../../Common/snackbar/components/snackbar";

const columns = [
  { header: "Name", key: "addedUser.name" },
  { header: "Email", key: "addedUser.email" },
  { header: "Mobile", key: "addedUser.mobile" },
];

const data = [
  { id: 1, name: "John Doe", age: 28, email: "john.doe@example.com", status: "Disabled" },
  { id: 2, name: "Jane Smith", age: 34, email: "jane.smith@example.com", status: "Disabled" },
  { id: 3, name: "Sam Green", age: 23, email: "sam.green@example.com", status: "Disabled" }
];

const TableWithNavigation = ({ data, columns, props, unique }) => {
  const navigate = useNavigate();
  const handleRowClick = (unique) => {
    const id = unique._id
    props.setClientId(id)
    navigate(`/ClientDetails/${id}`)

  };

  return <CommonTable data={data} columns={columns} onRowClick={handleRowClick} unique={unique} />;
};

class ExecutiveClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      columns: columns,
      emp_id: '',
      mobile: '',
      email: '',
      name: '',
      add: false,
      edit: false,
      deleteM: false,
      page: 1,
      pageSize: 10,
      user_id: 0
    }
  }

  componentDidMount() {
    
    this.props.viewExecutiveClient(this.props.login.token, this.props.exec.exec_id, this.state.page, this.state.pageSize)
  }

  render() {
    const handleChangePage = (event, newPage) => {
      this.props.viewExecutiveClient(this.props.login.token, this.props.exec.exec_id, newPage, this.state.pageSize)
      this.setState({ page: newPage });
    };
    return (
      <Grid padding={2}>
        <Card>
        <TableWithNavigation data={this.props.client.exec_client} columns={columns} props={this.props} unique={'addedUser'} />
        </Card>
        <Pagination
          style={{ display: "flex", justifyContent: 'center', padding: '10px' }}
          count={this.props.client.exec_client_length}
          size="small"
          shape='rounded'
          onChange={handleChangePage}
        />
        <Loader />
        {/* <Snackbar
          open={this.props.snackbar.response_received}
          close_snack_bar={this.props.close_snack_bar}
          message={this.props.snackbar.message}
        /> */}
      </Grid>
    )
  }
}

export default ExecutiveClient;