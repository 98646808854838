import React, { Component } from "react";
import { connect } from "react-redux";
import Login from "../Components/Client";
import { close_snack_bar } from "../../../Common/snackbar/action";
import { setClientId, viewClient, viewClientDetails, viewExecutiveClient } from "../Actions";
import { addClient } from "../Actions";
import { updateClient } from "../Actions";
import { deleteClient } from "../Actions";
export class Controller extends Component {
    render() {
        return (
            <Login {...this.props} />
        );
    }
};
export const mapStateToProps = store => {
    return {
        login: store.login,
        loader: store.loader,
        snackbar: store.snackbar,
        exec: store.exec,
        client: store.client,
    };
};
export const mapDispatchToProps = dispatch => {
    return {
        close_snack_bar: () => {
            dispatch(close_snack_bar());
        },
        viewClient: (token, page, pageSize) => {
            dispatch(viewClient(token, page, pageSize));
        },
        addClient: (token, user_id, name, emp_id, email, mobile, access, type) => {
            dispatch(addClient(token, user_id, name, emp_id, email, mobile, access, type));
        },
        updateClient: (token, user_id, name, email, mobile, type) => {
            dispatch(updateClient(token, user_id, name, email, mobile, type));
        },
        deleteClient: (token, user_id, type) => {
            dispatch(deleteClient(token, user_id, type));
        },
        viewExecutiveClient: (token, executive_id, page, pageSize) => {
            dispatch(viewExecutiveClient(token, executive_id, page, pageSize));
        },
        setClientId: (id) => {
            dispatch(setClientId(id));
        },
        viewClientDetails: (token, client_id) => {
            dispatch(viewClientDetails(token, client_id));
        }
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Controller);