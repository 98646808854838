import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, LoadScript, Marker, DirectionsRenderer } from '@react-google-maps/api';

const MapComponent = ({ startLocation, endLocation }) => {
  const mapContainerStyle = {
    width: '100%',
    height: '400px'
  };

  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState('');
  const [duration, setDuration] = useState('');
  const [mapRef, setMapRef] = useState(null);
  const directionsService = useRef(null);

  const onLoad = map => {
    setMapRef(map);
  };

  useEffect(() => {
    if (mapRef && startLocation.coordinates.length && endLocation.coordinates.length) {
      const bounds = new window.google.maps.LatLngBounds();
      bounds.extend(new window.google.maps.LatLng(startLocation.coordinates[1], startLocation.coordinates[0]));
      bounds.extend(new window.google.maps.LatLng(endLocation.coordinates[1], endLocation.coordinates[0]));
      mapRef.fitBounds(bounds);

      const start = new window.google.maps.LatLng(startLocation.coordinates[1], startLocation.coordinates[0]);
      const end = new window.google.maps.LatLng(endLocation.coordinates[1], endLocation.coordinates[0]);

      if (directionsService.current === null) {
        directionsService.current = new window.google.maps.DirectionsService();
      }

      directionsService.current.route(
        {
          origin: start,
          destination: end,
          travelMode: window.google.maps.TravelMode.WALKING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirections(result);
            const route = result.routes[0];
            const leg = route.legs[0];
            setDistance(leg.distance.text);
            setDuration(leg.duration.text);
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    }
  }, [mapRef, startLocation, endLocation]);

  return (
    <LoadScript googleMapsApiKey="AIzaSyA1NWtHAoW-by59g6tticj5Xf6Y3i9btv0">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        onLoad={onLoad}
      >
        <Marker position={{ lat: startLocation.coordinates[1], lng: startLocation.coordinates[0] }} />
        {endLocation.coordinates.length > 0 && (
          <Marker position={{ lat: endLocation.coordinates[1], lng: endLocation.coordinates[0] }} />
        )}
        {directions && (
          <DirectionsRenderer
            directions={directions}
            options={{
              polylineOptions: {
                strokeColor: '#0000FF',
                strokeOpacity: 1.0,
                strokeWeight: 2
              }
            }}
          />
        )}
      </GoogleMap>
      <div>
        <h2>Travel Information</h2>
        <p>Distance: {distance}</p>
        <p>Duration: {duration}</p>
      </div>
    </LoadScript>
  );
};

export default MapComponent;
