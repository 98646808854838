import React, { Component } from 'react';
import CommonTable from "../../../Common/Table/Table";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from '@mui/material';
import { color } from 'three/examples/jsm/nodes/Nodes.js';

function withNavigation(Component) {
    return function WrappedComponent(props) {
        const navigate = useNavigate();
        const location = useLocation();
        const params = useParams();
        return <Component {...props} navigate={navigate} location={location} params={params} />;
    };
}

const columns = [
    { header: "Model Name", key: "model" },
    { header: "Type", key: "type" },
    // { header: "Employee_Id", key: "emp_id" },
    { header: "Action", key: "action" },
];

const data = [
    { id: 1, name: "John Doe", age: 28, email: "john.doe@example.com", status: "Active" },
    { id: 2, name: "Jane Smith", age: 34, email: "jane.smith@example.com", status: "Active" },
    { id: 3, name: "Sam Green", age: 23, email: "sam.green@example.com", status: "Active" }
];
const TableWithNavigation = ({ data, columns, props, unique }) => {
    const navigate = useNavigate();

    const handleRowClick = (unique) => {
        // props.setExecutiveId(unique)
        // navigate(`/ModelDetails`);

    };

    return <CommonTable data={data} columns={columns} onRowClick={handleRowClick} unique={unique} />;
};
class Executive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: data,
            columns: columns,
            model: "",
            type: "",
            add: false,
            edit: false,
            deleteM: false,
            page: 1,
            pageSize: 10,
            user_id: 0
        }
    }


    processData = (data) => {
        return Array.isArray(data) && data.map((e) => ({
            ...e,
            status: (
                <span>{'Active'}</span>
            ),
            action: (
                <Grid container spacing={2} justifyContent={'center'}>
                    <Grid item>
                        <Button className='table-text' style={{ color: "rgb(20, 28, 76)", backgroundColor: "rgba(77, 85, 124, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(20, 28, 76)', padding: 0 }}
                            onClick={(event) => {
                                event.stopPropagation();
                                this.setState({
                                    edit: true,
                                    user_id: e._id,
                                    model: e.model,
                                    type: e.type,

                                });
                            }}>
                            Edit
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button className='table-text' style={{ color: "rgb(212, 44, 44)", backgroundColor: "rgba(220, 85, 85, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(212, 44, 44)', padding: 0 }}
                            onClick={(event) => {
                                event.stopPropagation();
                                this.setState({ deleteM: true, user_id: e._id, model: e.model });
                            }}>
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            ),
        }))
    }

    processColumn = (columns) => {
        return Array.isArray(columns) && columns.map((e) => ({
            ...e,
            action: 'action'
        }));
    }
    componentDidMount() {
        this.props.viewModel(this.props.login.token)
    }
    render() {
        // const handleChangePage = (event, newPage) => {
        //     this.props.viewExecutive(this.props.login.token, newPage, this.state.pageSize)
        //     this.setState({ page: newPage });
        // };
        const { model, type, add, edit, deleteM, user_id } = this.state;
        const newdata = this.processData(this.props.model.all_model);
        const newcolumns = this.processColumn(this.state.columns);

        return (
            <Grid padding={1} >
                <Grid display={'flex'} justifyContent={'flex-end'}>
                    <Grid pb={1}>
                        <Button className='table-text' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
                            onClick={() => {
                                this.setState({ add: true });
                            }}>
                            Add Model
                        </Button>
                    </Grid>
                </Grid>

                <Card>
                    <TableWithNavigation data={newdata} columns={newcolumns} props={this.props} unique={'_id'} />
                </Card>
                {/* <Pagination
                    style={{ display: "flex", justifyContent: 'center', padding: '10px' }}
                    count={this.props.exec.exe_length}
                    size="small"
                    shape='rounded'
                    onChange={handleChangePage}
                /> */}

                {/* /-------------------------------- ADD ------------------------------------/ */}
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={add}
                    onClose={() => {
                        this.setState({
                            add: false
                        })
                    }}
                    aria-labelledby="form-dialog-title"
                    sx={{
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
                        <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Add Model</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} mt={0}>
                            <Grid item xs={12} sm={6}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <DialogContentText className='table-text2' fontSize={'20px'}>Model:</DialogContentText>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            size='small'
                                            placeholder='Model Name'
                                            onChange={(event) => {
                                                this.setState({ model: event.target.value });
                                            }}
                                            value={model}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <DialogContentText className='table-text2' fontSize={'20px'}>type:</DialogContentText>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            size='small'
                                            placeholder='Type'
                                            onChange={(event) => {
                                                this.setState({ type: event.target.value });
                                            }}
                                            value={type}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button className='table-text2' style={{ border: "0.5px solid rgb(20, 28, 76)", color: "rgb(20, 28, 76)" }}
                            onClick={() => {
                                this.setState({ add: false })
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className='table-text2' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white" }}
                            onClick={() => {
                                this.props.addModel(this.props.login.token, model, type,);
                                this.setState({ add: false, model:'', type:'',})
                            }}
                        >
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* /-------------------------------- EDIT ------------------------------------/ */}
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={edit}
                    onClose={() => {
                        this.setState({
                            edit: false
                        })
                    }}
                    aria-labelledby="form-dialog-title"
                    sx={{
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
                        <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Edit Model</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} mt={0}>
                            <Grid item xs={12} sm={6}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <DialogContentText className='table-text2' fontSize={'20px'}>Model:</DialogContentText>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            size='small'
                                            placeholder='Model Name'
                                            onChange={(event) => {
                                                this.setState({ model: event.target.value });
                                            }}
                                            value={model}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Grid container alignItems={'center'}>
                                    <Grid item xs={12} sm={12} md={4}>
                                        <DialogContentText className='table-text2' fontSize={'20px'}>Type :</DialogContentText>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8}>
                                        <TextField
                                            type="text"
                                            fullWidth
                                            size='small'
                                            placeholder='Type'
                                            onChange={(event) => {
                                                this.setState({ type: event.target.value });
                                            }}
                                            value={type}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>


                    </DialogContent>
                    <DialogActions style={{ justifyContent: 'center' }}>
                        <Button className='table-text2' style={{ border: "0.5px solid rgb(20, 28, 76)", color: "rgb(20, 28, 76)" }}
                            onClick={() => {
                                this.setState({ edit: false })
                            }}
                        >
                            Cancel
                        </Button>
                        <Button className='table-text2' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white" }}
                            onClick={() => {
                                this.props.updateModel(this.props.login.token, user_id, model, type);
                                this.setState({ edit: false })
                            }}
                        >
                            Save Change
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* /-------------------------------- DELETE ------------------------------------/ */}
                <Dialog
                    maxWidth="xs"
                    fullWidth
                    open={deleteM}
                    onClose={() => {
                        this.setState({
                            deleteM: false
                        })
                    }}
                    aria-labelledby="form-dialog-title"
                    sx={{
                        "& .MuiDialog-paper": {
                            borderRadius: "10px",
                        },
                    }}
                >
                    <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
                        <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Delete Model</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className='table-text2' fontSize={'20px'}>Are you sure you want to delete <span style={{fontWeight: 600}}>{model}</span> ?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button className='table-text2' style={{ backgroundColor: "rgb(212, 44, 44)", color: "white" }}
                            onClick={() => {
                                this.props.deleteModel(this.props.login.token, user_id);
                                this.setState({ deleteM: false })
                            }}
                        >
                            Delete
                        </Button>
                        <Button className='table-text2' style={{ color: "white", backgroundColor: "rgb(20, 28, 76)" }}
                            onClick={() => {
                                this.setState({ deleteM: false })
                            }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

            </Grid>
        );
    }
}

export default withNavigation(Executive);

