import * as CONSTANT from "./Constants";
const initial_state = {
  all_exe: [],
  exe_length: 0,
  exec_id: ''
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case CONSTANT.EXECUTIVE:
      return state = { ...state, all_exe: action.payload.result, exe_length: action.payload.count };
    case CONSTANT.EXECUTIVE_ID:
      return state = { ...state, exec_id: action.payload };


    default:
      return state;
  }
}
