import * as CONSTANT from "./Constants";
const initial_state = {
    all_model: [],
    model_length: 0,
    exec_id: ''
};
export default function reducer(state = initial_state, action) {
    switch (action.type) {
        case CONSTANT.MODEL:
            return state = { ...state, all_model: action.payload.result, model_length: action.payload.count };
        case CONSTANT.EXECUTIVE_ID:
            return state = { ...state, exec_id: action.payload };


        default:
            return state;
    }
}
