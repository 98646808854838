import React, { Component } from 'react'
import CommonTable from "../../../Common/Table/Table";
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import Loader from '../../../Common/loader/containers/loader_cont';
import Snackbar from "../../../Common/snackbar/components/snackbar";
import { Button, Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogContentText, TextField, DialogActions } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
function withNavigation(Component) {
  return function WrappedComponent(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    return <Component {...props} navigate={navigate} location={location} params={params} />;
  };
}

const data = [
  { id: 1, name: "John Doe", age: 28, email: "john.doe@example.com", status: "Active" },
  { id: 2, name: "Jane Smith", age: 34, email: "jane.smith@example.com", status: "Disabled" },
  { id: 3, name: "Sam Green", age: 23, email: "sam.green@example.com", status: "Active" }
];
const TableWithNavigation = ({ data, columns, props, unique }) => {
  const navigate = useNavigate();
  const handleRowClick = (unique) => {
    const id = unique?._id
    props.setClientId(id)
    navigate(`/ClientDetails/${id}`)

  };

  return <CommonTable data={data} columns={columns} onRowClick={handleRowClick} unique={unique} />;
};
class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: data,
      columns: [
        { header: "Name", key: "name" },
        ...(this.props.type === 'SA' ? [{ header: "Executive", key: "addedBy.name" }] : []),
        { header: "Email", key: "addedUser.email" },
        { header: "Mobile", key: "addedUser.mobile" },
        { header: "Action", key: "action" },
      ],
      mobcolumns: [
        { header: "Clients", key: "name" },
        { header: "Action", key: "action" },
      ],
      emp_id: '',
      mobile: '',
      email: '',
      name: '',
      add: false,
      edit: false,
      deleteM: false,
      page: 1,
      pageSize: 10,
      user_id: 0,
      isMobileView: false,
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.setState({ isMobileView: window.innerWidth <= 768 }); // Adjust breakpoint as needed
  };

  renderName = (addedUser) => {
    const { isMobileView } = this.state;

    if (!isMobileView) {
      return <span>{addedUser?.name}</span>;
    } else {
      return (
        <>
          <span>{addedUser?.name}</span><br />
          <span>{addedUser?.mobile}</span><br />
        </>
      );
    }
  };

  processData = (data) => {
    return Array.isArray(data) && data?.map((e) => ({
      ...e,
      name: this.renderName(e.addedUser),
      action: (
        <Grid container spacing={2} justifyContent={'center'}>
          {this.props.login.type !== 'SA' &&
            <Link to={`/ClientDetails/${e.addedUser?._id}`}>
              <Grid item>
                <IconButton
                  className='table-text'
                  style={{
                    color: "rgb(20, 28, 76)",
                    backgroundColor: "rgba(77, 85, 124, 0.3)",
                    textTransform: 'capitalize',
                    border: '1px solid rgb(20, 28, 76)',
                    marginRight: '8px',
                    padding: 0
                  }}
                  onClick={(event) => {
                    this.setState({
                      view: true,
                      user_id: e.addedUser?._id,
                      name: e.addedUser?.name,
                      email: e.addedUser?.email,
                      mobile: e.addedUser?.mobile,
                    });
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </Grid >
            </Link>
          }

          {this.props.login.type === 'SA' &&
            <>
              <Grid item>
                <Button className='table-text' style={{ color: "rgb(20, 28, 76)", backgroundColor: "rgba(77, 85, 124, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(20, 28, 76)', padding: 0, }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.setState({
                      edit: true,
                      user_id: e.addedUser?._id,
                      name: e.addedUser?.name,
                      email: e.addedUser?.email,
                      mobile: e.addedUser?.mobile,

                    });
                  }}>
                  Edit
                </Button >
              </Grid>
              <Grid item >

                <Button className='table-text' style={{ color: "rgb(212, 44, 44)", backgroundColor: "rgba(220, 85, 85, 0.3)", textTransform: 'capitalize', border: '1px solid rgb(212, 44, 44)', padding: 0 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    this.setState({
                      deleteM: true,
                      user_id: e.addedUser?._id,
                      name: e.addedUser?.name,
                    });
                  }}>
                  Delete
                </Button>
              </Grid>
            </>
          }
        </Grid >
      ),
    }))
  }

  processColumn = (columns) => {
    return Array.isArray(columns) && columns.map((e) => ({
      ...e,
      action: 'action'
    }));
  }
  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
    if (this.props.login.type === 'SA') {
      this.props.viewClient(this.props.login.token, this.state.page, this.state.pageSize)
    }
    else {
      this.props.viewExecutiveClient(this.props.login.token, this.props.login.user_id, this.state.page, this.state.pageSize)
    }

  }
  render() {
    const handleChangePage = (event, newPage) => {
      this.props.viewClient(this.props.login.token, newPage, this.state.pageSize)
      this.setState({ page: newPage });
    };
    const { mobile, email, name, add, edit, deleteM, user_id, isMobileView } = this.state;
    const columnsToRender = isMobileView ? this.state.mobcolumns : this.state.columns;
    let newdata = []
    if (this.props.login.type === 'SA') {
      newdata = this.processData(this.props.client.all_client);
    } else {
      newdata = this.processData(this.props.client.exec_client);
    }
    const newcolumns = this.processColumn(columnsToRender);
    return (
      <Grid padding={1}>
        <Grid display={'flex'} justifyContent={'flex-end'}>
          <Grid pb={1}>
            <Button className='table-text' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.setState({ add: true });
              }}>
              Add Client
            </Button>
          </Grid>
        </Grid>
        <Card>
          <TableWithNavigation data={newdata} columns={newcolumns} props={this.props} unique={'addedUser'} />
        </Card>
        <Pagination style={{ display: "flex", justifyContent: 'center', padding: '10px' }}

          count={this.props.login.type === 'SA' ? this.props.client.client_length : this.props.client.exec_client_length}
          size="small"
          shape='rounded'
          onChange={handleChangePage}
        />

        {/* /-------------------------------- ADD ------------------------------------/ */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={add}
          onClose={() => {
            this.setState({
              add: false
            })
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
            <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Add Client</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} >
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} >
                    <DialogContentText className='table-text2' fontSize={'20px'}>Name:</DialogContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      fullWidth
                      size='small'
                      placeholder='Client Name'
                      onChange={(event) => {
                        this.setState({ name: event.target.value });
                      }}
                      value={name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container mt={0} spacing={2}>
              <Grid item xs={12}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Phone No.:</DialogContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      fullWidth
                      size='small'
                      placeholder='Client Phone No.'
                      onChange={(event) => {
                        this.setState({ mobile: event.target.value });
                      }}
                      value={mobile}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Email Id :</DialogContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="email"
                      fullWidth
                      size='small'
                      placeholder='Client Email Id'
                      onChange={(event) => {
                        this.setState({ email: event.target.value });
                      }}
                      value={email}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button className='table-text2' style={{ border: "0.5px solid rgb(20, 28, 76)", color: "rgb(20, 28, 76)" }}
              onClick={() => {
                this.setState({ add: false })
              }}
            >
              Cancel
            </Button>
            <Button className='table-text2' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white" }}
              onClick={() => {
                this.props.addClient(this.props.login.token, this.props.login.user_id, name, email, mobile, 'C', this.props.login.type);
                this.setState({ add: false, name: '', email: '', mobile: '' })
              }}
            >
              Add
            </Button>
          </DialogActions>
        </Dialog>

        {/* /-------------------------------- EDIT ------------------------------------/ */}
        <Dialog
          maxWidth="sm"
          fullWidth
          open={edit}
          onClose={() => {
            this.setState({
              edit: false
            })
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
            <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Edit Employee</Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2} mt={0}>
              <Grid item xs={12} >
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} >
                    <DialogContentText className='table-text2' fontSize={'20px'}>Name:</DialogContentText>
                  </Grid>
                  <Grid item xs={12} >
                    <TextField
                      type="text"
                      fullWidth
                      size='small'
                      placeholder='Client Name'
                      onChange={(event) => {
                        this.setState({ name: event.target.value });
                      }}
                      value={name}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container mt={0} spacing={2}>
              <Grid item xs={12}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12}>
                    <DialogContentText className='table-text2' fontSize={'20px'}>Phone No.:</DialogContentText>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type="number"
                      fullWidth
                      size='small'
                      placeholder='Client Phone No.'
                      onChange={(event) => {
                        this.setState({ mobile: event.target.value });
                      }}
                      value={mobile}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container alignItems={'center'}>
                  <Grid item xs={12} >
                    <DialogContentText className='table-text2' fontSize={'20px'}>Email Id :</DialogContentText>
                  </Grid>
                  <Grid item xs={12} >
                    <TextField
                      type="email"
                      fullWidth
                      size='small'
                      placeholder='Client Email Id'
                      onChange={(event) => {
                        this.setState({ email: event.target.value });
                      }}
                      value={email}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button className='table-text2' style={{ border: "0.5px solid rgb(20, 28, 76)", color: "rgb(20, 28, 76)" }}
              onClick={() => {
                this.setState({ edit: false })
              }}
            >
              Cancel
            </Button>
            <Button className='table-text2' style={{ backgroundColor: "rgb(20, 28, 76)", color: "white" }}
              onClick={() => {
                this.props.updateClient(this.props.login.token, user_id, name, email, mobile, this.props.login.type);
                this.setState({ edit: false })
              }}
            >
              Save Change
            </Button>
          </DialogActions>
        </Dialog>

        {/* /-------------------------------- DELETE ------------------------------------/ */}
        <Dialog
          maxWidth="xs"
          fullWidth
          open={deleteM}
          onClose={() => {
            this.setState({
              eddeleteMit: false
            })
          }}
          aria-labelledby="form-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "10px",
            },
          }}
        >
          <DialogTitle backgroundColor={'rgba(146, 144, 195, 0.2)'}>
            <Typography variant="h5" className='table-text2' style={{ color: "rgb(20, 28, 76)" }}>Delete Client</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" mt={2}>
              Are you sure you want to delete <span style={{ fontWeight: 600 }}>{name}</span> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              this.props.deleteClient(this.props.login.token, user_id, this.props.login.type);
              this.setState({ deleteM: false });
            }} style={{ backgroundColor: "rgb(212, 44, 44)", color: "white", textTransform: 'capitalize' }}>
              Delete
            </Button>
            <Button style={{ backgroundColor: "rgb(20, 28, 76)", color: "white", textTransform: 'capitalize' }}
              onClick={() => {
                this.setState({
                  deleteM: false
                })
              }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>

        <Loader />
        {/* <Snackbar
          open={this.props.snackbar.response_received}
          close_snack_bar={this.props.close_snack_bar}
          message={this.props.snackbar.message}
        /> */}
      </Grid>
    )
  }
}

export default withNavigation(Client);