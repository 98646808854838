import * as CONSTANT from "./Constants";
const initial_state = {
  all_client: [],
  exec_client: [],
  client_length: 0,
  exec_client_length: 0,
  client_details: {},
  test_drive: [],
  check_start: {},
  drive_record:[],
  excel: []
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case CONSTANT.CLIENT:
      return state = { ...state, all_client: action.payload.result, client_length: action.payload.count };

    case CONSTANT.EXECUTIVE_CLIENT:
      return state = { ...state, exec_client: action.payload.result, exec_client_length: action.payload.count };
    case CONSTANT.CLIENT_ID:
      return state = { ...state, client_id: action.payload };
    case CONSTANT.CLIENT_DETAILS:
      return state = { ...state, client_details: action.payload.result };
    case CONSTANT.TEST_DRIVE:
      return state = { ...state, test_drive: action.payload };
    case CONSTANT.CHECK_START:
      return state = { ...state, check_start: action.payload };
    case CONSTANT.REMOVE_START:
      return state = { ...state, check_start: {} };
    case CONSTANT.DRIVE_RECORD:
      return state = { ...state, drive_record: action.payload };
    case CONSTANT.EXCEL:
      return state = { ...state, excel: action.payload };

    default:
      return state;
  }
}
