import React, { Component } from 'react'
import {
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
  DialogContentText
} from '@mui/material';
import { Eye, EyeOff } from 'lucide-react';
import '../../Common/Style/common.css';
import Car from '../../Common/Image/car.jpg'
import Forget from '../../Common/Image/forget.jpg'
import Drive from '../../Common/Image/AUTO TRACKER.png'

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      form: true,
      forget: false,
      showPassword: false,
      email: '',
      password: '',
      token: "",
    };
  }
  handleClickShowPassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  render() {
    const { showPassword, forget, email, password } = this.state;
    return (
      <Grid>
        <Dialog
          open={this.state.form}
          maxWidth="md"
          fullWidth
          sx={{ backgroundColor: 'rgb(146, 144, 195)' }}
        >
          <DialogContent>
            <Grid container>
              {!forget &&
                <>
                  <Grid item xs={false} sm={5} md={6} lg={6} className="imageContainer">
                    <img src={Car} alt='Car' className="responsiveImage" />
                  </Grid>
                  <Grid item xs={12} sm={7} md={6} lg={6} alignContent={'center'}>
                    <Grid display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                      <Typography className='header' variant='h4' textAlign={'center'}>Login</Typography>
                      {/* <img src={Drive} alt='TestDrive' width={100} /> */}
                    </Grid>
                    <Grid container mt={4}>
                      <Grid item xs={12}>
                        <Typography className='text'>
                          Email Id
                        </Typography>
                        <TextField
                          fullWidth
                          variant='outlined'
                          size='small'
                          value={email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value })
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid container mt={1}>
                      <Grid item xs={12}>
                        <Typography className='text'>
                          Password
                        </Typography>
                        <FormControl fullWidth>
                          <OutlinedInput
                            type={showPassword ? 'text' : 'password'}
                            size='small'
                            value={password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={this.handleClickShowPassword}
                                  onMouseDown={this.handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <EyeOff /> : <Eye />}
                                </IconButton>
                              </InputAdornment>
                            }
                            onChange={(e) => {
                              this.setState({ password: e.target.value })
                            }}
                          />
                        </FormControl>
                        <Typography mt={.5} fontSize={12} color={'primary'} style={{ cursor: 'pointer' }}
                          onClick={() => {
                            this.setState({ forget: true });
                          }}>
                          Forgot password?
                        </Typography>
                      </Grid>
                    </Grid>
                    <center>
                      <Button variant='contained'
                        sx={{
                          marginTop: 5,
                          backgroundColor: "rgb(20, 28, 76)",
                          color: "rgb(146, 144, 195)",
                          '&:hover': {
                            backgroundColor: "rgb(146, 144, 195)",
                            color: "rgb(20, 28, 76)",
                          }
                        }}
                        onClick={() => {
                          this.props.loginFetch(email, password)
                          this.setState({
                            email: '',
                            password: '',
                            token: ''
                          })
                        }}>
                        Login
                      </Button>
                    </center>
                  </Grid>
                </>
              }
              {forget &&
                <>
                  <Grid item xs={12} sm={7} md={6} lg={6} alignContent={'center'}>
                    <Grid>
                      <center><img src={Drive} alt='TestDrive' height={75} /></center>
                      <Typography className='header' variant='h5' textAlign={'center'} mt={2.5}>Forget Password</Typography>
                    </Grid>
                    <DialogContentText color={'rgb(20, 28, 76)'} mt={5}>Please give your registered email id for the password.</DialogContentText>
                    <Grid container>
                      <Grid item xs={12} mt={.5}>
                        <TextField
                          fullWidth
                          placeholder='Email Id'
                          variant='outlined'
                          size='small'
                          value={email}
                          onChange={(e) => {
                            this.setState({ email: e.target.value })
                          }}
                        />
                      </Grid>
                    </Grid>
                    <center>
                      <Button variant='contained'
                        sx={{
                          marginTop: 5,
                          backgroundColor: "rgb(20, 28, 76)",
                          color: "rgb(146, 144, 195)",
                          '&:hover': {
                            backgroundColor: "rgb(146, 144, 195)",
                            color: "rgb(20, 28, 76)",
                          }
                        }}
                        onClick={() => {
                          this.props.forgotpassword(email)
                          this.setState({
                            forget: false,
                            email: ''
                          })
                        }}>
                        Submit
                      </Button>
                    </center>
                  </Grid>
                  <Grid item xs={false} sm={5} md={6} lg={6} className="imageContainer">
                    <img src={Forget} alt='Forget' className="responsiveImage" />
                  </Grid>
                </>
              }
            </Grid>
          </DialogContent>
        </Dialog>
      </Grid>
    )
  }
}
